export const posts = [
  {
    title: "How I saved 2 million using ligegate future account",
    category: "business",
    img: "/images/blog7.png",
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Est, fugit.",
  },
  {
    title: "How I saved 2 million using ligegate future account",
    category: "foreign",
    img: "/images/blog1.png",
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Est, fugit.",
  },
  {
    title: "How I saved 2 million using ligegate future account",
    category: "business",
    img: "/images/blog-2.png",
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Est, fugit.",
  },
  {
    title: "How I saved 2 million using ligegate future account",
    category: "testimonial",
    img: "/images/blog3.png",
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Est, fugit.",
  },
  {
    title: "How I saved 2 million using ligegate future account",
    category: "blog",
    img: "/images/blog4.png",
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Est, fugit.",
  },
  {
    title: "How I saved 2 million using ligegate future account",
    category: "foreign",
    img: "/images/blog5.png",
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Est, fugit.",
  },
  {
    title: "How I saved 2 million using ligegate future account",
    category: "business",
    img: "/images/blog6.png",
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Est, fugit.",
  },
  {
    title: "How I saved 2 million using ligegate future account",
    category: "blog",
    img: "/images/blog8.png",
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Est, fugit.",
  },
  {
    title: "How I saved 2 million using ligegate future account",
    category: "testimonial",
    img: "/images/blog9.png",
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Est, fugit.",
  },
];
